<template>
 <div>
    <b-modal
      id="bannersModal"
      modal-class="modal-primary"
      :title="$t('Banners')"
      hide-footer
      centered
      size="lg"
    >
      <b-form @submit.prevent="saveBanners">
        <b-form-group
          :label="$t('Title')"
          label-for="v-title"
        >
          <b-form-input
            id="v-title"
            type="text"
            v-model="bannersForm.title"
          />
        </b-form-group>


        <!-- design -->
        <b-col cols="12" class="text-left">
          <h3 class="text-warning"><feather-icon size="20" icon="ImageIcon" />{{$t('Banner Images')}}</h3>
          <p>{{$t('You can upload multi image')}}</p>
          <template v-for="(image, i) in bannersForm.media">
            <div :key="i" 
              style="padding: 5px; border: 1px solid #eee; border-radius: 5px;" 
              class="mb-1"
            >
              <b-row>
                <b-col cols="12" sm="6" class="text-center">
                  <template v-if="image.url">
                    <b-img :src="image.url" fluid thumbnail />
                  </template>
                  <template v-else>
                    <div class="image-upload-wrap">
                      <input class="file-upload-input" type='file' @change="selectImage($event, i)" accept="image/jpg, image/jpeg, image/png, image/gif" />
                      <div class="drag-text">
                        <h4 class="text-primary">
                          <feather-icon
                            icon="UploadCloudIcon"
                            size="50"
                          /><br>
                          {{$t('Drag and drop a file or click to add Image')}}
                        </h4>
                      </div>
                    </div>
                  </template>

                  <b-button 
                    variant="outline-danger"
                    size="sm"
                    pill
                    class="mt-1"
                    @click="deleteImage(i)"
                  >
                    <feather-icon icon="Trash2Icon" />
                    {{$t('Delete Banner')}}
                  </b-button>
                </b-col>
                <b-col cols="12" sm="6">
                  <!-- Url -->
                  <b-form-group
                    :label="$t('Url')"
                    label-for="v-url"
                    :description="$t('The url that redirect when click on banner')"
                  >
                    <b-form-input
                      id="v-url"
                      v-model="image.custom_properties.link"
                    />
                  </b-form-group>

                  <!-- Sort -->
                  <b-form-group
                    :label="$t('Sort')"
                    label-for="v-sort"
                    :description="$t('Banner appearance order')"
                  >
                    <b-form-input
                      id="v-sort"
                      type="number"
                      v-model="image.custom_properties.sort"
                    />
                  </b-form-group>

                  <!-- For mobile -->
                  <b-form-group
                    :label="$t('For Mobile')"
                    label-for="v-for-mobile"
                    :description="$t('Determine whether this banner appears on mobile screens or large screens')"
                  >
                    <b-form-select
                      id="v-for-mobile"
                      :options="[{value: 'no', text: $t('No')}, {value: 'yes', text: $t('Yes')}]"
                      v-model="image.custom_properties.for_mobile"
                    />
                  </b-form-group>

                </b-col>
              </b-row>
            </div>
          </template>
          <b-button 
            variant="outline-success"
            @click="addImage"
            size="sm"
            pill
          >
            <feather-icon size="15" icon="PlusCircleIcon" />
            {{$t('Add Image')}}
          </b-button>
        </b-col>


        <!-- design -->
        <b-col cols="12">
          <hr>
          <h3 class="text-left text-warning"><feather-icon size="20" icon="FeatherIcon" />{{$t('Banner Design')}}</h3>
          <p>{{$t('You Can here select how banner display with multi options of design')}}</p>
        </b-col>

        <!-- Banner Type -->
        <b-col cols="12">
          <h4 class="text-center">{{$t('Banner Type')}}</h4>
          <b-row>
            <b-col md="6">
              <b-form-radio
                value="fixed"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.banner_type"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-1.png" fluid />
                <p class="text-center">
                  ثابت
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="carousel"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.banner_type"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-gif-mhd.gif" fluid />
                <p class="text-center">
                  متحرك
                </p>
              </b-form-radio>
            </b-col>
          </b-row>
        </b-col>

        <!-- Banner Size -->
        <b-col cols="12">
          <hr>
          <h4 class="text-center">{{$t('Large Screen Size')}}</h4>
          <b-row>
            <b-col md="6">
              <b-form-radio
                value="12"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-1.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 300px<br>
                  العرض = 1300px
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="6"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-6.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 300px<br>
                  العرض = 750px
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="4"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-4.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 300px<br>
                  العرض = 450px
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="3"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-3.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 400px<br>
                  العرض = 400px
                </p>
              </b-form-radio>
            </b-col>
          </b-row>
        </b-col>

        <!-- Mobile Size -->
        <b-col cols="12">
          <hr>
          <h4 class="text-center">{{$t('Mobile Screen Size')}}</h4>
          <b-row>
            <b-col md="3" cols="6">
              <b-form-radio
                value="12"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.mobile_size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-mobile-12.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 400px<br>
                  العرض = 400px
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="3" cols="6">
              <b-form-radio
                value="6"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.mobile_size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-mobile-6.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 200px<br>
                  العرض = 200px
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="3" cols="6">
              <b-form-radio
                value="4"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.mobile_size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-mobile-4.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 150px<br>
                  العرض = 150px
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="3" cols="6">
              <b-form-radio
                value="3"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.mobile_size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-mobile-3.png" fluid />
                <p class="text-center">
                  <b>المقاس</b><br>
                  الطول = 100px<br>
                  العرض = 100px
                </p>
              </b-form-radio>
            </b-col>
          </b-row>
        </b-col>

        <!-- container_size -->
        <b-col cols="12">
          <hr>
          <h4 class="text-center">{{$t('Banner Container')}}</h4>
          <b-row>
            <b-col md="6">
              <b-form-radio
                value="container-fluid"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.container_size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-1.png" fluid />
                <p class="text-center">
                  كامل الصفحة
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="container"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.container_size"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-center.png" fluid />
                <p class="text-center">
                  منتصف الصفحة
                </p>
              </b-form-radio>
            </b-col>
          </b-row>
        </b-col>

        <!-- Banner Effects -->
        <b-col cols="12">
          <hr>
          <h4 class="text-center">{{$t('Banner Effects')}}</h4>
          <b-row>
            <b-col md="6">
              <b-form-radio
                value="banner_effect_normal"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.banner_effects"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-size-1.png" fluid />
                <p class="text-center">
                  عادي
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="banner_effect_shadow"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.banner_effects"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-effect-shadow.png" fluid />
                <p class="text-center">
                  ظل
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="banner_effect_refliction"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.banner_effects"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-effect-refliction.png" fluid />
                <p class="text-center">
                  انعكاس
                </p>
              </b-form-radio>
            </b-col>
            <b-col md="6">
              <b-form-radio
                value="banner_effect_radius"
                class="radio-border custom-control-success mb-2"
                v-model="bannersForm.banner_effects"
              >
                <b-img src="https://cdn.mhd.sa/asset/images/banner-size/banner-effect-radius.png" fluid />
                <p class="text-center">
                أطراف مقوسة
                </p>
              </b-form-radio>
            </b-col>
          </b-row>
        </b-col>

        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">
        <template v-if="bannersForm.id">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
        <template v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Add')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Adding')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      bannersForm: {
        title: '',
        banner_type: 'fixed',
        size: '12',
        mobile_size: '12',
        container_size: 'container-fluid',
        banner_effects: 'banner_effect_normal',
        position_id: 1,
        images: [],
        id: null
      },
      isLoadingSave: false
    }
  },
  methods: {
    openBannersModal(position_id, banner) {
      this.bannersForm = {
        title: banner ? banner.title : '',
        banner_type: banner ? banner.banner_type : 'fixed',
        size: banner ? banner.size : '12',
        mobile_size: banner ? banner.mobile_size : '12',
        container_size: banner ? banner.container_size : 'container-fluid',
        banner_effects: banner ? banner.banner_effects : 'banner_effect_normal',
        media: banner ? banner.media : [],
        media_delete: [],
        position_id: position_id,
        store_id: this.$store.state.store.id,
        id: banner ? banner.id : null
      }
      this.$bvModal.show("bannersModal")
    },
    saveBanners() {
      // let form = new FormData(); 
      // Object.entries(this.bannersForm).forEach(([key, value]) => {
      //   form.append(key, value);
      // });

      this.isLoadingSave = true;
      let path;
      if (this.bannersForm.id) { // update current
        path = '/banners/update';
      } else { // store new
        path = '/banners/store';
      }

      useJwt.post(path,this.bannersForm)
      .then((response) => {
        // console.log(response.data);
        // this.$parent.$parent.product = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide("bannersModal")
        this.$parent.getResults();
      })
      .catch(error => {
        console.log(error.response.data);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    addImage() {
      this.bannersForm.media.push({
        url: '',
        custom_properties:{
          link: '',
          sort: 0,
          for_mobile: 'no'
        }
      })
    },
    selectImage(event, i) {
      this.bannersForm.media[i].url = URL.createObjectURL(event.target.files[0]);
      
      let reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        // console.log(reader.result);
        this.bannersForm.media[i].file = reader.result;
      };
    },
    deleteImage(i) {
      this.bannersForm.media_delete.push(this.bannersForm.media[i].id);
      this.$delete(this.bannersForm.media, i);
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/scss/variables/variables.scss"
.radio-border 
  padding: 3px
  border: 1px solid #ececec
  border-radius: 3px
  background: #f7f7f7

  p
    margin-bottom: 0
    margin-top: 0.5rem

.image-upload-wrap 
  position: relative
  margin-top: 20px
  border: 1px dashed #ccc
  border-radius: 10px


.image-upload-wrap:hover 
  background-color: #f7f7f7


.file-upload-input 
  position: absolute
  margin: 0
  padding: 0
  width: 100%
  height: 100%
  outline: none
  opacity: 0
  cursor: pointer
  right: 0


.drag-text 
  text-align: center


.drag-text h4 
  font-weight: 100
  padding: 30px 0

</style>
