<template>
  <div>
    <b-form @submit.prevent="update">
      <b-row>

        <!-- Product theme size -->
        <b-col cols="12">
          <p class="text-success">{{$t('You can on this page choose the product design that fits with your brand and products')}}</p>
          <hr>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Product theme size')"
            label-for="v-product-theme-size"
          >
            <b-row>
              <b-col md="4">
                <b-form-radio
                  value="full"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme_size"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/default_shadow.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="half"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme_size"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/half.png" fluid />
                </b-form-radio>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <!-- Product theme -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Product theme')"
            label-for="v-product-theme"
          >
            <b-row>
              <b-col md="4">
                <b-form-radio
                  value="default_shadow"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/default_shadow.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="default_shadow_radius"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/default_shadow_radius.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="flat_image"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/flat_image.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="flat_image_without_name"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/flat_image_without_name.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="flat_image_gray"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/flat_image_gray.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="full_image"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/full_image.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="right_image"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/right_image.png" fluid />
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-radio
                  value="left_image"
                  class="radio-border custom-control-success mb-2"
                  v-model="theme_config.product_theme"
                >
                  <b-img src="https://cdn.mhd.sa/asset/images/design-system/products-box/left_image.png" fluid />
                </b-form-radio>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: ['theme_config'],
  data() {
    return {
      isLoadingSave: false
    }
  },
  mounted() {
    // console.log(this.theme_config);
  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/store/config/updateTheme',this.theme_config)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$parent.theme_config = response.data.data;
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="sass" scoped>
.radio-border 
  padding: 0px
  border: 1px solid #ececec
  border-radius: 3px
  background: #f7f7f7

  p
    margin-bottom: 0
    margin-top: 0.5rem
</style>
