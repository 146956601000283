<template>
  <div>
    <b-form @submit.prevent="update">
      <b-row>

        <!-- name -->
        <b-col cols="6" sm="3">
          <b-form-group
            :label="$t('Primary Color')"
            label-for="v-primary-color"
          >
            <b-form-input
              id="v-primary-color"
              placeholder="Primary Color"
              v-model="theme_config.primary_color"
              type="color"
            />
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: ['theme_config'],
  data() {
    return {
      isLoadingSave: false
    }
  },
  mounted() {
    // console.log(this.theme_config);
  },
  methods: {
    update() {
      this.isLoadingSave = true;
      useJwt.post('/store/config/updateTheme',this.theme_config)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$parent.theme_config = response.data.data;
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
