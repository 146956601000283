<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="fd-container">

          <!-- Header -->
          <div class="fd-header">
            {{$t('Header')}}
          </div>

          <!-- Top Position -->
          <div class="fd-position">
            <b-badge variant="light-success" class="fd-top-position-title">
              {{$t('Top Position')}}
            </b-badge>
            <draggable
                v-model="top_position"
                class="list-group list-group-flush cursor-move"
                tag="ul"
                v-if="Object.keys(top_position).length > 0"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <b-list-group-item
                    v-for="(positionable, indexT) in top_position"
                    :key="indexT"
                    tag="li"
                  >
                  <template v-if="positionable.positionable_type == 'App\\Models\\FeaturedProduct'">
                    <div class="text-center">
                      <b-badge variant="light-secondary" class="badge-icon">
                        <feather-icon
                          icon="AwardIcon"
                          size="35"
                        />
                        {{$t('Featured Products')}}
                      </b-badge>
                      <p>{{positionable.featuredProduct.title}}</p>
                      <p>
                        <template v-for="(product, indexP) in positionable.featuredProduct.products_available">
                          <b-img :src="product.media[0].url" :key="`products`+indexP" height="80" v-if="product.media.length" />
                        </template>
                      </p>
                      <div class="text-right">
                        <b-button
                          variant="flat-secondary"
                          class="mr-1"
                          size="sm"
                          @click="$refs.featuredProductsModal.openFeaturedProductsModal(1, positionable.featuredProduct)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="15"
                          />
                        </b-button>
                        <b-button
                          variant="flat-danger"
                          size="sm"
                          @click="deleteFeaturedProducts(1, positionable.featuredProduct.id)"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="15"
                          />
                        </b-button>
                      </div>
                    </div>
                  </template>
                  <template v-if="positionable.positionable_type == 'App\\Models\\Banner'">
                    <div class="text-center">
                      <b-badge variant="light-secondary" class="badge-icon">
                        <feather-icon
                          icon="ImageIcon"
                          size="35"
                        />
                        {{$t('Banner')}}
                      </b-badge>
                      <p>{{positionable.banner.title}}</p>
                      <p><img :src="positionable.banner.media[0].url" height="100" v-if="positionable.banner.media.length" /></p>

                      <!-- edit & delete icons -->
                      <div class="text-right">
                        <b-button
                          variant="flat-secondary"
                          class="mr-1"
                          size="sm"
                          @click="$refs.bannersModal.openBannersModal(1, positionable.banner)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="15"
                          />
                        </b-button>
                        <b-button
                          variant="flat-danger"
                          size="sm"
                          @click="deleteBanner(1, positionable.banner.id)"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="15"
                          />
                        </b-button>
                      </div>
                    </div>
                  </template>
                  </b-list-group-item>
                </transition-group>
            </draggable>
            <div class="fd-position-add-new p-1 mt-1 text-center">
              <b-button
              variant="outline-primary"
              class="ml-1 mr-1"
              @click="$refs.bannersModal.openBannersModal(1)"
              >
                +
                <feather-icon
                  icon="ImageIcon"
                  size="15"
                />
                {{$t('Add Banner')}}
              </b-button>
              <b-button
              variant="outline-primary"
              class="ml-1 mr-1"
              @click="$refs.featuredProductsModal.openFeaturedProductsModal(1)"
              >
                +
                <feather-icon
                  icon="AwardIcon"
                  size="15"
                />
                {{$t('Add Featured Products')}}
              </b-button>
            </div>
          </div>
          <hr>
          <div class="fd-featured-categories text-center">
            <b-badge variant="light-success" class="mb-1">
              {{$t('Featured Categories')}}
            </b-badge>
            <p>{{$t('Here display Featured Categories')}}</p>
          </div>
          <hr>

          <!-- Bottom Position -->
          <div class="fd-position">
            <b-badge variant="light-success" class="fd-top-position-title">
              {{$t('Bottom Position')}}
            </b-badge>
            <draggable
              v-model="bottom_position"
              class="list-group list-group-flush cursor-move"
              tag="ul"
              v-if="Object.keys(bottom_position).length > 0"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <b-list-group-item
                  v-for="(positionable, indexB) in bottom_position"
                  :key="indexB"
                  tag="li"
                >
                  <template v-if="positionable.positionable_type == 'App\\Models\\FeaturedProduct'">
                    <div class="text-center">
                      <b-badge variant="light-secondary" class="badge-icon">
                        <feather-icon
                          icon="AwardIcon"
                          size="35"
                        />
                        {{$t('Featured Products')}}
                      </b-badge>
                      <p>{{positionable.featuredProduct.title}}</p>
                      <p>
                        <template v-for="(product, indexP) in positionable.featuredProduct.products_available">
                          <b-img :src="product.media[0].url" :key="`products`+indexP" height="80" v-if="product.media.length" />
                        </template>
                      </p>
                      <div class="text-right">
                        <b-button
                          variant="flat-secondary"
                          class="mr-1"
                          size="sm"
                          @click="$refs.featuredProductsModal.openFeaturedProductsModal(2, positionable.featuredProduct)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="15"
                          />
                        </b-button>
                        <b-button
                          variant="flat-danger"
                          size="sm"
                          @click="deleteFeaturedProducts(2, positionable.featuredProduct.id)"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="15"
                          />
                        </b-button>
                      </div>
                    </div>
                  </template>
                  <template v-if="positionable.positionable_type == 'App\\Models\\Banner'">
                    <div class="text-center">
                      <b-badge variant="light-secondary" class="badge-icon">
                        <feather-icon
                          icon="ImageIcon"
                          size="35"
                        />
                        {{$t('Banner')}}
                      </b-badge>
                      <p>{{positionable.banner.title}}</p>
                      <p><img :src="positionable.banner.media[0].url" height="100" v-if="positionable.banner.media.length" /></p>

                      <!-- edit & delete icons -->
                      <div class="text-right">
                        <b-button
                          variant="flat-secondary"
                          class="mr-1"
                          size="sm"
                          @click="$refs.bannersModal.openBannersModal(2, positionable.banner)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="15"
                          />
                        </b-button>
                        <b-button
                          variant="flat-danger"
                          size="sm"
                          @click="deleteBanner(2, positionable.banner.id)"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="15"
                          />
                        </b-button>
                      </div>
                    </div>
                  </template>
                </b-list-group-item>
              </transition-group>
            </draggable>
            <div class="fd-position-add-new p-1 mt-1 text-center">
              <b-button
              variant="outline-primary"
              class="ml-1 mr-1"
              @click="$refs.bannersModal.openBannersModal(2)"
              >
                +
                <feather-icon
                  icon="ImageIcon"
                  size="15"
                />
                {{$t('Add Banner')}}
              </b-button>
              <b-button
              variant="outline-primary"
              class="ml-1 mr-1"
              @click="$refs.featuredProductsModal.openFeaturedProductsModal(2)"
              >
                +
                <feather-icon
                  icon="AwardIcon"
                  size="15"
                />
                {{$t('Add Featured Products')}}
              </b-button>
            </div>
          </div>

          <!-- Footer -->
          <div class="fd-footer">
            {{$t('Footer')}}
          </div>
        </div>
      </b-col>
      <!-- submit -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="gradient-primary"
          class="mr-1 mt-1"
          @click="saveSort"
        >
          <template v-if="!isLoadingSave">
            {{$t('Save Sort')}}
          </template>
          <template v-if="isLoadingSave">
            {{$t('Saveing Sort')}}...
            <b-spinner small />
          </template>
        </b-button>
      </b-col>
    </b-row>

    <!-- Featured Products modal -->
    <fd-featured-products-modal ref="featuredProductsModal"></fd-featured-products-modal>

    <!-- Banners modal -->
    <fd-banners-modal ref="bannersModal"></fd-banners-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import draggable from 'vuedraggable'
import FdBannersModal from './FdBannersModal.vue'
import FdFeaturedProductsModal from './FdFeaturedProductsModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    draggable,
    FdBannersModal,
    FdFeaturedProductsModal
  },
  props: [''],
  data() {
    return {
      top_position: {},
      bottom_position: {},
      isLoadingSave: false
    }
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      useJwt.get('/store/config/positions')
      .then((response) => {
        // console.log(response.data.data)
        this.top_position = response.data.data.top_position
        this.bottom_position = response.data.data.bottom_position
        this.isLoading = false
      })
      .catch(response => {
          console.log(response);
      });
    },
    deleteBanner(position_id, id) {
      this.isLoading = true
      useJwt.post('/banners/destroy',{id: id, store_id: this.$store.state.store.id, position_id: position_id})
      .then((response) => {
        // console.log(response.data.data)
        // this.isLoading = false
        this.getResults();
      })
      .catch(response => {
          console.log(response);
      });
    },
    deleteFeaturedProducts(position_id, id) {
      this.isLoading = true
      useJwt.post('/featured-products/destroy',{id: id, store_id: this.$store.state.store.id, position_id: position_id})
      .then((response) => {
        // console.log(response.data.data)
        // this.isLoading = false
        this.getResults();
      })
      .catch(response => {
          console.log(response);
      });
    },
    saveSort() {
      this.isLoadingSave = true
      useJwt.post('/store/config/positions/save-sort',{top_position: this.top_position, bottom_position: this.bottom_position, store_id: this.$store.state.store.id})
      .then((response) => {
        // console.log(response.data.data)
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(response => {
          console.log(response);
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.fd-container {
  border: 1px solid #eee;
  .fd-header {
    border-bottom: 1px solid #eee;
  }
  .fd-footer {
    border-top: 1px solid #eee;
  }
  .fd-header, .fd-footer {
    padding: 10px;
    text-align: center;
    background: #f7f7f7;
  }

  .fd-position, .fd-position {
    padding: 10px;
    .badge-icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    .fd-position-add-new {
      border: 1px solid #eee;
    }
  }
}
</style>
