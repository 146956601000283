<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in filters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t(filter.title) }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
  },
  props: {
    
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const filters = [
      { title: 'Colors', icon: 'DropletIcon', route: { name: 'frontend-design' } },
      { title: 'Product Design', icon: 'CodesandboxIcon', route: { name: 'frontend-design-filter', params: { filter: 'product-design' } } },
      { title: 'Home Page Design', icon: 'FeatherIcon', route: { name: 'frontend-design-filter', params: { filter: 'homepage' } } },
      { title: 'Custom CSS', icon: 'FeatherIcon', route: { name: 'frontend-design-filter', params: { filter: 'custom-css' } } },
    ]

    return {
      perfectScrollbarSettings,
      filters,
      isDynamicRouteActive,
    }
  },
}
</script>

<style lang="sass" scoped>
@import "../../assets/scss/variables/variables.scss"

.sidebar-menu-list
  height: 100% !important

.list-group-item.active
  border-color: $primary !important

</style>
