<template>
  <div>
    <b-form @submit.prevent="update">
      <b-row>

        <!-- name -->
        <b-col cols="12">
          <b-form-group
            :label="$t('Custom CSS')"
            label-for="v-custom-css"
          >
            <b-form-textarea
              id="v-custom-css"
              rows="3"
              max-rows="20"
              v-model="custom_css.custom_css"
              dir="ltr"
            />
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-primary"
            class="mr-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoadingSave: false,
      custom_css: {
        custom_css: null
      },
    }
  },
  mounted() {
    // console.log(this.theme_config);
    this.getResults()
  },
  methods: {
    getResults() {
      useJwt.post('/store/config/get-by-id',{key: 'custom_css'})
      .then((response) => {
        // console.log(response.data)
        if (response.data.data.length) {
          this.custom_css = response.data.data.custom_css
        }
        this.isLoading = false
      })
      .catch(response => {
          console.log(response);
      });
    },
    update() {
      this.isLoadingSave = true;
      useJwt.post('/store/config/updateCustomCss',this.custom_css)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$parent.custom_css = response.data.data;
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
