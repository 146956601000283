<template>
 <div>
    <b-modal
      id="featuredProducts"
      modal-class="modal-primary"
      :title="$t('Featured Products')"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <b-form @submit.prevent="saveFeaturedProducts">
        <b-form-group
          :label="$t('Title')"
          label-for="v-title"
        >
          <b-form-input
            id="v-title"
            type="text"
            v-model="featuredProductsForm.title"
          />
        </b-form-group>

        <!-- products -->
        <b-form-group
          :label="$t('Products')"
          label-for="v-products"
        >
          <v-select
            v-model="featuredProductsForm.product_id"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="products"
            multiple
          >
            <template #option="{ name, largeThumbnail }">
              <b-img :src="largeThumbnail" width="50" height="50" />
              <span> {{ name }}</span>
            </template>
          </v-select>
          <div class="mt-1">
            <template v-for="(featured_products, indexI) in featuredProductsForm.product_id">
              <b-img :src="featured_products.largeThumbnail" width="50" height="50" :key="indexI" class="mr-1" />
            </template>
          </div>
        </b-form-group>
        <input type="hidden" value="" v-model="featuredProductsForm.position_id" />
      
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">
        <template v-if="featuredProductsForm.id">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
        <template v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Add')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Adding')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  mounted() {
    this.getProducts();
  },
  data() {
    return {
      featuredProductsForm: {
        title: '',
        product_id: [],
        position_id: 1,
        id: null
      },
      isLoadingSave: false,
      products: [],
    }
  },
  methods: {
    getProducts(){
      useJwt.get('/products/get-all')
      .then((response) => {
        // console.log(response.data)
        this.products = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
    openFeaturedProductsModal(position_id, featuredProducts) {
      this.featuredProductsForm = {
        title: featuredProducts ? featuredProducts.title : '',
        product_id: featuredProducts ? featuredProducts.products_available : [],
        position_id: position_id,
        store_id: this.$store.state.store.id,
        id: featuredProducts ? featuredProducts.id : null
      }
      this.$bvModal.show("featuredProducts")
    },
    saveFeaturedProducts() {
      this.isLoadingSave = true;
      let path;
      if (this.featuredProductsForm.id) { // update current
        path = '/featured-products/update';
      } else { // store new
        path = '/featured-products/store';
      }

      useJwt.post(path,this.featuredProductsForm)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide("featuredProducts")
        this.$parent.getResults();
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/scss/variables/variables.scss"

</style>
