<template>
  <div class="frontend-design-list-wrapper">
    <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
        spinner-mediam
        >
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in"
          >
              <div>
                  <div
                  class="body-content-overlay"
                  :class="{'show': mqShallShowLeftSidebar}"
                  @click="mqShallShowLeftSidebar = false"
                  />
                  <div class="todo-app-list">

                      <!-- App Toggler Header -->
                      <div class="app-fixed-search d-flex align-items-center p-0 mb-1">
                          <!-- Toggler -->
                          <div class="sidebar-toggle d-block d-lg-none">
                          <feather-icon
                              icon="MenuIcon"
                              size="21"
                              class="cursor-pointer"
                              @click="mqShallShowLeftSidebar = true"
                          />
                          </div>
                      </div>

                      <!-- Product Information -->
                      <div class="todo-task-list-wrapper" v-if="tabOpened == 'frontend-design-colors'">
                        <fd-colors :theme_config="theme_config"></fd-colors>
                      </div>

                      <!-- Product Design -->
                      <div class="todo-task-list-wrapper" v-if="tabOpened == 'product-design'">
                          <fd-product-design :theme_config="theme_config"></fd-product-design>
                      </div>

                      <!-- Homepage -->
                      <div class="todo-task-list-wrapper" v-if="tabOpened == 'homepage'">
                          <fd-homepage></fd-homepage>
                      </div>

                      <!-- Custom CSS -->
                      <div class="todo-task-list-wrapper" v-if="tabOpened == 'custom-css'">
                          <fd-custom-css></fd-custom-css>
                      </div>
                  </div>
              </div>
          </transition>
    </b-overlay>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <fd-left-sidebar
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import {
  ref, watch, computed
} from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import FdLeftSidebar from './FdLeftSidebar.vue'
import FdColors from './FdColors.vue'
import FdProductDesign from './FdProductDesign.vue'
import FdHomepage from './FdHomepage.vue'
import FdCustomCss from './FdCustomCss.vue'

export default {
  components: {
    VuePerfectScrollbar,
    FdColors,
    FdProductDesign,
    FdHomepage,
    FdCustomCss,

    // App SFC
    FdLeftSidebar,
  },
  mounted() {
    this.getResults()
  },
  data() {
    return {
      theme_config: {},
      isLoading: true,
    }
  },
  methods: {
    getResults() {
      useJwt.post('/store/config/get-by-id',{key: 'theme_config'})
      .then((response) => {
        // console.log(response.data)
        this.theme_config = response.data.data.theme_config || {};
        this.isLoading = false
      })
      .catch(response => {
          console.log(response);
      });
    }
  },
  setup() {
    const tabOpened = ref([])

    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)
    tabOpened.value = routeParams.value.filter ? routeParams.value.filter : 'frontend-design-colors';

    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      tabOpened.value = routeParams.value.filter ? routeParams.value.filter : 'frontend-design-colors';
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      tabOpened,
      perfectScrollbarSettings,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
.frontend-design-list-wrapper {
    background: #FFF;
}
.todo-app-list {
    padding: 1.5rem;
}
.app-fixed-search {
    display: none !important;
}
@media only screen and (max-width: 920px) {
    .app-fixed-search {
        display: block !important;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
